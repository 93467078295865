import React from "react"
import { withPrefix } from "gatsby"
import AddToCart from "../carts/AddToCart"

const DnaExplorerCarrier = () => (
  <div className="card h-100">
    <div className="card-body text-center d-flex flex-column">
      <img
        className="img-fluid px-lg-5 mb-4"
        src={withPrefix("/img/product-box.png")}
        alt="Product"
      />
      <img
        className="img-fluid px-lg-5 mb-4"
        src={withPrefix("img/logo/DNA-Explorer-Carrier.png")}
        alt="DNA Explorer Carrier"
      />
      <p>
        Discover your carrier status to prevent your future family from
        potentially inheriting genetic disorders.
      </p>
      <div className="text-center">
        <span className="badge bg-gray text-gray mb-4">13 reports</span>
      </div>
      <div className="text-center my-auto">
        <div className="row align-items-center justify-content-center">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/carrier-reports.svg")}
              className="img-fluid mb-2"
              alt="carrier"
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-8">
            <p className="small-explanation">
              {" "}
              Genetic mutation screening for recessive genetic disorders
            </p>
          </div>
        </div>
        <div className="row align-items-center justify-content-center my-2">
          <div className="col-2">
            <span>
              <i className="fa fa-plus text-gray"></i>
            </span>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/carrier-speech.svg")}
              className="img-fluid mb-2"
              alt="comments"
            />
          </div>
        </div>
        <div className="row mb-4 align-items-center justify-content-center">
          <div className="col-10">
            <p className="small-explanation">
              Comes with Individual Comments from Professional Genetic
              Counsellor
            </p>
          </div>
        </div>
      </div>
      <div className="price-cta mt-auto">
        <div className="row my-4">
          <div className="col-12">
            <span className="h2 mr-2">RM 1800</span>
          </div>
        </div>
        <AddToCart
          buttonName="Add to cart"
          price="1800"
          id="35"
          class="btn-explorer-carrier-purple btn-block"
          alaCarte="false"
        />
        <a
          href={withPrefix("/products/dna-explorer-carrier")}
          className="text-center"
          style={{ color: "inherit" }}
        >
          Learn More
        </a>
      </div>
    </div>
  </div>
)

export default DnaExplorerCarrier
