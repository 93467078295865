import React from "react"
import { withPrefix } from "gatsby"

const DnaExplorerPersonal = () => (
  <div className="card h-100">
    <div className="card-body text-center d-flex flex-column">
      <img
        className="img-fluid px-lg-5 mb-4"
        src={withPrefix("/img/product-box.png")}
        alt="Product"
      />
      <img
        className="img-fluid px-lg-5 mb-4"
        src={withPrefix("img/logo/DNA-Explorer-Personal.png")}
        alt="DNA Explorer Personal"
      />
      <p>
        Select from 8 different report categories and create your own customized
        DNA reports
      </p>
      <div className="text-center">
        <span className="badge bg-gray text-gray mb-4 mr-2">
          12-137 reports
        </span>
        <span className="badge bg-gray text-gray mb-4">8 categories</span>
      </div>
      <div className="text-center my-auto">
        <div className="row mb-2 align-items-end justify-content-center">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/personal-nutrition.png")}
              className="img-fluid"
              alt="nutrition"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix(
                "/img/illustrations/personal-weightmanagement.svg"
              )}
              className="img-fluid"
              alt="weight management"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/personal-fitness.svg")}
              className="img-fluid"
              alt="fitness"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/personal-innerpotential.png")}
              className="img-fluid"
              alt="inner potential"
            />
          </div>
        </div>
        <div className="row mb-4 align-items-center justify-content-center">
          <div className="col-3">
            <p className="small-explanation">Nutrition</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Weight Management</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Fitness</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Inner Potential</p>
          </div>
        </div>
        <div className="row align-items-end justify-content-center mb-2">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/personal-skin.svg")}
              className="img-fluid"
              alt="skin"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/personal-allergy.png")}
              className="img-fluid"
              alt="allergy"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/personal-healthrisk.png")}
              className="img-fluid"
              alt="health risks"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/personal-carrier.svg")}
              className="img-fluid"
              alt="carrier"
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mb-4">
          <div className="col-3">
            <p className="small-explanation">Skin</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Allergy</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Health Risks</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Carrier Status</p>
          </div>
        </div>
      </div>
      <div className="price-cta mt-auto">
        <div className="row align-items-center my-4">
          <div className="col-12">
            <span>from</span>
            <span className="h2 ml-2">RM 179</span>
          </div>
        </div>
        <a
          href="/products/dna-explorer-personal"
          className="btn btn-explorer-blue btn-block mb-3"
        >
          Buy now
        </a>

      </div>
    </div>
  </div>
)

export default DnaExplorerPersonal
