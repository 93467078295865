import React, { Component, useState } from "react"
import API from "../../services/api"

function AddToCart(props) {
  const [, setState] = useState()

  let onAddToCart = e => {
    var cart_id = JSON.parse(localStorage.getItem("advanx_cart_id"))
    if (props.categoryName) {
      API.addAlaCarte(cart_id[0], props.id, props.categoryName)
    } else {
      API.addItem(cart_id[0], props.id, props.price, props.alaCarte)
    }
    ++window.$itemCount
    setState({})
  }

  return (
    <a
      href="#"
      className={"btn " + props.class + "  mb-3"}
      onClick={onAddToCart}
    >
      {props.buttonName}
    </a>
  )
}

export default AddToCart
