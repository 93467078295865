import React from "react"
import { withPrefix } from "gatsby"
import AddToCart from "../carts/AddToCart"

const DnaExplorerPrime = () => (
  <div className="card h-100">
    <div className="card-body text-center d-flex flex-column">
      <img
        className="img-fluid px-lg-5 mb-4"
        src={withPrefix("/img/product-box.png")}
        alt="Product"
      />
      <img
        className="img-fluid px-lg-5 mb-4"
        src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
        alt="DNA Explorer Prime"
      />
      <p>
        The wellness test that you need to understand how to live a healthier
        lifestyle based on your DNA.
      </p>
      <div className="text-center">
        <span className="badge bg-gray text-gray mb-4 mr-2">124 reports</span>
        <span className="badge bg-gray text-gray mb-4">7 categories</span>
      </div>
      <div className="text-center my-auto">
        <div className="row mb-2 align-items-end justify-content-center">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/prime-nutrition.svg")}
              className="img-fluid"
              alt="nutrition"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/prime-weightmanagement.svg")}
              className="img-fluid"
              alt="weight management"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/prime-fitness.svg")}
              className="img-fluid"
              alt="fitness"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/prime-innerpotential.svg")}
              className="img-fluid"
              alt="inner potential"
            />
          </div>
        </div>
        <div className="row mb-4 align-items-center justify-content-center">
          <div className="col-3">
            <p className="small-explanation">Nutrition</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Weight Management</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Fitness</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Inner Potential</p>
          </div>
        </div>
        <div className="row align-items-end justify-content-center mb-2">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/prime-skin.svg")}
              className="img-fluid"
              alt="skin"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/prime-allergy.svg")}
              className="img-fluid"
              alt="allergy"
            />
          </div>
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/prime-healthrisk.svg")}
              className="img-fluid"
              alt="health risks"
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mb-4">
          <div className="col-3">
            <p className="small-explanation">Skin</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Allergy</p>
          </div>
          <div className="col-3">
            <p className="small-explanation">Health Risks</p>
          </div>
        </div>
      </div>
      <div className="price-cta mt-auto">
        <div className="row align-items-center my-4">
          <div className="col-12">
            <span className="h2 mr-2">RM 1599</span>
          </div>
        </div>
        <AddToCart
          buttonName="Add to cart"
          price="1599"
          id="34"
          class="btn-explorer-yellow btn-block"
          alaCarte="false"
        />
        <a
          href={withPrefix("/products/dna-explorer-prime")}
          className="text-center"
          style={{ color: "inherit" }}
        >
          Learn More
        </a>
      </div>
    </div>
  </div>
)

export default DnaExplorerPrime
