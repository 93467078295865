import React from "react"

export default ({ className, to, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="arrow arrow-previous"
    aria-label={to}
  >
    <i className="fa fa-caret-left"></i>
  </button>
)
