import React from "react"
import { withPrefix } from "gatsby"
import Slider from "react-slick"
import DnaExplorerPersonal from "./product/DnaExplorerPersonal"
import DnaExplorerPrime from "./product/DnaExplorerPrime"
import DnaExplorerCarrier from "./product/DnaExplorerCarrier"
import NextSliderArrow from "./slider/NextSliderArrow"
import PreviousSliderArrow from "./slider/PreviousSliderArrow"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PreviousSliderArrow />,
  nextArrow: <NextSliderArrow />,
}

const CTA = () => (
  <div>
    <div className="d-none d-md-block">
      <div className="row my-5">
        <div className="col-lg-4">
          <DnaExplorerPersonal />
        </div>
        <div className="col-lg-4">
          <DnaExplorerCarrier />
        </div>
        <div className="col-lg-4">
          <DnaExplorerPrime />
        </div>
      </div>
    </div>
    <div className="d-block d-md-none">
      <Slider {...settings}>
        <DnaExplorerCarrier />
        <DnaExplorerPrime />
        <DnaExplorerPersonal />
      </Slider>
    </div>
  </div>
)

export default CTA
